import Button from 'react-bootstrap/Button';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Component} from "react";
import axios from "axios";
import {URL_REGISTER, VERIFY} from "../lib/Constants";
import Layout from "../components/Layout";
import NumberFormat from "react-number-format";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

// Schema for yup
const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("*Must be a valid email address")
        .max(100, "*Email must be less than 100 characters")
        .required("*Email is required"),
    phone: Yup.string()
        .matches(phoneRegExp, "*Phone number is not valid")
        .required("*Phone number required"),
    terms: Yup.boolean()
        .oneOf([true], "The terms and conditions must be accepted."),
});


class Info extends Component {
    state = {
        verifyError: null,
        showModal: false,
        showSpinner: false,
    };

    sendVerificationCode(email, phone) {
        const {history} = this.props;
        this.setState({
            showSpinner: true
        }, () => {
            const msisdn = "1" + phone.replace(VERIFY.CONTACT_NO_PATTERN, "");
            sessionStorage.setItem("EMAIL", email);
            sessionStorage.setItem("PHONE", msisdn);
            axios.post(URL_REGISTER, {
                email,
                msisdn,
                otpTo: "msisdn"
            }).then(response => {
                console.log(response);
                sessionStorage.setItem("OTP_ID", response.data.otpId);
                history.push("/verify");
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                if (this._ismounted) {
                    this.setState({
                        showSpinner: true
                    });
                }
            });
        });
    }

    componentDidMount() {
        this._ismounted = true;
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    render() {
        const {history} = this.props;
        const {showSpinner, showModal, frontImage} = this.state;
        return (
            <Layout showSpinner={showSpinner} history={history}>
                <Formik
                    initialValues={{email: "", phone: "", terms: false}}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        this.sendVerificationCode(values.email, values.phone);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <Container className="form-fields">
                                <Row>
                                    <Col sm={12}><h1>Identity verification</h1></Col>
                                    <Col sm={12}><h3>Enter your primary email address and mobile number.</h3></Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>

                                        <Form.Group controlId="formEmail">
                                            <Form.Label>Your email address</Form.Label>
                                            <Form.Control size="lg" type="email" name="email"
                                                          placeholder="email@email.com"
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.email}
                                                          className={touched.email && errors.email ? "has-error" : null}/>
                                            {touched.email && errors.email ? (
                                                <div className="error-message">{errors.email}</div>
                                            ) : null}
                                        </Form.Group>

                                        <Form.Group controlId="formPhone">
                                            <Form.Label>Your mobile no.</Form.Label>
                                            <NumberFormat
                                                format={"(###) ###-####"}
                                                className={touched.phone && errors.phone ? "form-control form-control-lg has-error" : "form-control form-control-lg"}
                                                type="tel"
                                                name="phone"
                                                value={values.phone}
                                                placeholder="(000) 000-0000"
                                                onChange={handleChange}
                                            />

                                            {touched.phone && errors.phone ? (
                                                <div className="error-message">{errors.phone}</div>
                                            ) : null}
                                        </Form.Group>
                                        <div className="form-group" controlId="acceptTerms">
                                            <div className="form-check">
                                                <input
                                                    className={touched.terms && errors.terms ? "form-check-input has-error" : "form-check-input"}
                                                    type="checkbox"
                                                    name="terms"
                                                    id="terms"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.terms}
                                                />
                                                <label htmlFor='terms' className="form-check-label">
                                                    By continuing you confirm that you have read and agree to the <a
                                                    href="https://www.comsavings.com/legal/membership-application-consents"
                                                    target="_blank">terms and conditions.</a>
                                                </label>
                                            </div>
                                            {touched.terms && errors.terms ? (
                                                <div className="error-message">{errors.terms}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="submit-btn-container">
                                    <Col md={{span: 6, offset: 3}}>
                                        <Button type="submit" variant="primary" block size="lg" disabled={isSubmitting}>
                                            Continue
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </Layout>
        );
    }

}

export default Info;