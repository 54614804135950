import Button from 'react-bootstrap/Button';
import {Col, Container, Form, Image, Modal, Row} from 'react-bootstrap';
import React, {Component} from "react";
import * as Yup from "yup";
import {Formik, useField, useFormikContext} from "formik";
import axios from "axios";
import {POSTAL, S_INSTNT_FINGER_PRINT, S_INSTNT_TXN_ID, URL_BIOMETRICS, URL_PROVINCE} from "../lib/Constants";
import publicIp from "public-ip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format, getMonth, getYear, parse} from "date-fns";
import Layout from "../components/Layout";
import moment from 'moment';
import MaskedInput from "react-text-mask";
import {isMobile} from "react-device-detect";
import {InstntSignupProvider} from "@instnt/instnt-react-js";

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required("*First name is required"),
    surName: Yup.string()
        .required("*Last name is required"),
    dob: Yup.string()
        .required("*Date of birth is required"),
    physicalAddress: Yup.string()
        .required("*Address is required"),
    city: Yup.string()
        .required("*City is required"),
    province: Yup.string()
        .required("*Province is required"),
    zip: Yup.string()
        .required("*Postal code is required"),
});

const range = (start, end) => {
    const length = end - start;
    return Array.from({length}, (_, i) => end - i);
}

export const DatePickerField = ({...props}) => {
    const {setFieldValue} = useFormikContext();
    const [field] = useField(props);
    const years = range(getYear(props.minDate), getYear(props.maxDate));
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];
    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            onChange={val => {
                setFieldValue(field.name, val);
            }}
            renderCustomHeader={({
                                     date,
                                     changeYear,
                                     changeMonth,
                                     decreaseMonth,
                                     increaseMonth,
                                     prevMonthButtonDisabled,
                                     nextMonthButtonDisabled
                                 }) => (
                <div>
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {"<"}
                    </button>
                    <select
                        value={getYear(date)}
                        onChange={({target: {value}}) => changeYear(value)}
                    >
                        {years.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <select
                        value={months[getMonth(date)]}
                        onChange={({target: {value}}) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                        {months.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {">"}
                    </button>
                </div>
            )}
        />
    );
};
export default class Review extends Component {


    constructor(props) {
        super(props);
        this.state = {
            verifyError: null,
            showModal: false,
            showSpinner: false,
            frontImage: null,
            firstName: "",
            middleName: "",
            dob: "",
            physicalAddress: "",
            city: "",
            province: "",
            zip: "",
            email: "",
            surName: "",
            loadInstntData: true,
            disableEdit: false,
            allowContinue: true,
            instData: null,
            provinceList: [],
            minDate: moment(new Date()).subtract(100, 'y').toDate(),
            maxDate: moment(new Date()).subtract(18, 'y').toDate(),
            idType: "",
            instnttxnid: null,
            fingerprint: null,
        };
    }


    componentDidMount() {
        console.log("componentDidMount");
        const {history} = this.props;
        const token = sessionStorage.getItem("TOKEN");
        const instnttxnid = sessionStorage.getItem(S_INSTNT_TXN_ID);
        const fingerprint = sessionStorage.getItem(S_INSTNT_FINGER_PRINT);
        if (instnttxnid) {
            this.setState({instnttxnid, fingerprint});
        }
        if (!token) {
            history.push("/");
        } else {
            const img = sessionStorage.getItem("SELFIE_IMAGE");
            this.setState({
                frontImage: img
            });
            axios.get(URL_PROVINCE, {}).then((result) => {
                let provinceList = [];
                provinceList = result.data.map((elem, idx) => {
                    return (
                        <option
                            value={elem.code}
                            key={elem.code + "-" + elem.description}
                            eventKey={idx}
                            className="[ dropdown__option ]"
                        >
                            {elem.description}
                        </option>
                    );
                });
                this.setState({
                    provinceList
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("componentDidUpdate");
        const {loadInstntData} = this.state;
        if (loadInstntData) {
            const currentInstntData = sessionStorage.getItem("INSTNT_DATA");
            const idType = sessionStorage.getItem("ID_TYPE");
            if (currentInstntData) {
                const instData = JSON.parse(currentInstntData);
                console.log("loading instData", instData.firstName);
                this.setState({
                    firstName: instData.firstName,
                    middleName: instData.middleName,
                    surName: instData.surName,
                    dob: instData.dateOfBirth ? parse(instData.dateOfBirth, "yyyy-MM-dd", new Date()) : "",
                    physicalAddress: instData.address,
                    city: instData.city,
                    province: instData.state,
                    zip: instData.postalCode,
                    loadInstntData: false,
                    disableEdit: idType === "Passport" ? false : true,
                    allowContinue: idType === "Passport" ? true : false,
                    instData,
                    idType,
                })
            }
        }
    }

    formURL = (url, paramList) => {
        return url.replace(/%(\d+)/g, (_, n) => paramList[+n - 1]);
    };

    async submitInfo(firstName, middleName, surName, dob, physicalAddress, city, province, zip) {
        const {instData, instnttxnid, fingerprint} = this.state;
        const {history} = this.props;
        this.setState({
            showSpinner: true
        });
        console.log("handleSubmit", firstName, middleName, surName, dob, physicalAddress, city, province, zip);
        let ipAddress = await publicIp.v4();
        const url = this.formURL(URL_BIOMETRICS, [sessionStorage.getItem("OTP_ID")])
        const token = sessionStorage.getItem("TOKEN");
        axios.post(url, {
            instnttxnid,
            fingerprint,
            firstName,
            middleName,
            surName,
            dob: format(dob, "yyyy-MM-dd"),
            physicalAddress,
            city,
            "state": province,
            zip,
            ipAddress,
            "email": sessionStorage.getItem("EMAIL"),
            "mobileNumber": sessionStorage.getItem("PHONE"),
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            sessionStorage.setItem("END_FLAG", "SUCCESS");
            history.push("/end");
        }).catch(error => {
            console.log(error);
            sessionStorage.setItem("END_FLAG", "ERROR");
            history.push("/end");
        }).finally(() => {
            if (this._ismounted) {
                this.setState({
                    showSpinner: false
                });
            }
        });

    }

    infoCorrect() {
        console.log("correct");
        this.setState({
            disableEdit: true,
            allowContinue: true
        })
    }

    infoNotCorrect() {
        console.log("incorrect")
        this.setState({
            disableEdit: false,
            allowContinue: true
        });
    }

    handleClose() {
        const {history} = this.props;
        history.push("/");
    }

    setDOB = (value) => {
        this.setState({
            dob: value
        });
    }

    onEventHandler = (event) => {
        console.log("Instnt event: ", event);
        switch (event.type) {
            case "transaction.initiated":
                const fingerprint = document.getElementById("fingerprint_txt");

                this.setState({
                    insnt: event.data.instnt,
                    instnttxnid: event.data.instnt.instnttxnid,
                    fingerprint: fingerprint.value,
                });
                console.log("new instnttxnid", event.data.instnt.instnttxnid);
                break;
            default:
                console.log("unhandled instnt event ", event);
        }
    };

    render() {
        console.log("render");
        const {
            showSpinner,
            showModal,
            frontImage,
            firstName,
            middleName,
            surName,
            dob,
            physicalAddress,
            city,
            province,
            zip,
            disableEdit,
            allowContinue,
            provinceList,
            minDate,
            maxDate,
            idType,
            instnttxnid,
        } = this.state;
        const {history} = this.props;
        return (
            <Layout showSpinner={showSpinner} history={history}>
                {!instnttxnid && (
                    <InstntSignupProvider
                        formKey={process.env.REACT_APP_INSTNT_KEY}
                        onEvent={this.onEventHandler}
                        serviceURL={process.env.REACT_APP_INSTNT_URL}
                        idmetrics_version={process.env.REACT_APP_METRICS_VERSION}
                    >
                    </InstntSignupProvider>
                )}
                <Modal
                    show={showModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={this.handleClose.bind(this)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Success!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Image src="/check.png"/>
                        <p>Your information has been submitted successfully.</p>
                        <p>
                            An email confirmation has been sent to the email address you provided and a
                            representative from CSCU will be in touch with you shortly.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose.bind(this)}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Formik
                    enableReinitialize
                    initialValues={{
                        firstName,
                        middleName,
                        surName,
                        dob,
                        physicalAddress,
                        city,
                        province,
                        zip
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        console.log("testing");
                        setSubmitting(true);
                        this.submitInfo(values.firstName, values.middleName, values.surName, values.dob, values.physicalAddress, values.city, values.province, values.zip);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <Container className="form-fields">
                                {!isMobile && (
                                    <Row>
                                        <Col sm={12}><h1>Identity verification</h1></Col>
                                        <Col sm={12}><h3>Please enter your details.</h3></Col>
                                    </Row>
                                )}
                                {isMobile && (
                                    <>
                                        <Row>
                                            <Col sm={12}><h1>Thank you</h1></Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}><p>Please review the information we scanned from your ID is
                                                correct.</p></Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}><p>You can update your info should you need to.</p></Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className="text-center"><Image className="selfieImageReview"
                                                                                        src={frontImage ? frontImage : "/camera.png"}
                                                                                        roundedCircle></Image></Col>
                                        </Row>
                                    </>
                                )}
                                <Row>
                                    <Col sm={12}><h2>Personal</h2></Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <Form.Group controlId="formCode">
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control size="lg"
                                                          disabled={disableEdit}
                                                          type="text"
                                                          name="firstName"
                                                          placeholder="First name"
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.firstName}
                                                          className={touched.firstName && errors.firstName ? "has-error" : null}/>
                                            {touched.firstName && errors.firstName ? (
                                                <div className="error-message">{errors.firstName}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="formMiddleName">
                                            <Form.Label>Middle name</Form.Label>
                                            <Form.Control size="lg"
                                                          disabled={disableEdit}
                                                          type="text"
                                                          name="middleName"
                                                          placeholder="Middle name"
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.middleName}
                                                          className={touched.middleName && errors.middleName ? "has-error" : null}/>
                                            {touched.middleName && errors.middleName ? (
                                                <div className="error-message">{errors.middleName}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="formLastName">
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control size="lg"
                                                          disabled={disableEdit}
                                                          type="text"
                                                          name="surName"
                                                          placeholder="Last name"
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.surName}
                                                          className={touched.surName && errors.surName ? "has-error" : null}/>
                                            {touched.surName && errors.surName ? (
                                                <div className="error-message">{errors.surName}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="formDOB">
                                            <Form.Label>Date of birth</Form.Label>
                                            <DatePickerField name="dob" id="dob"
                                                             showYearDropdown
                                                             showMonthDropdown
                                                             minDate={minDate}
                                                             maxDate={maxDate}
                                                             yearDropdownItemNumber={60}
                                                             placeholderText="YYYY-MM-DD"
                                                             selected={values.dob}
                                                             disabled={disableEdit}
                                                             onBlur={handleBlur}
                                                             dateFormat="yyyy-MM-dd"
                                                             onChange={(date) => this.setDOB(date)}
                                                             className="[datepicker form-control form-control-lg ]"/>
                                            {touched.dob && errors.dob ? (
                                                <div className="error-message">{errors.dob}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}><h2>Address</h2></Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="formAddress">
                                            <Form.Label>Street address</Form.Label>
                                            <Form.Control size="lg"
                                                          disabled={disableEdit}
                                                          type="text"
                                                          name="physicalAddress"
                                                          placeholder="Address"
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.physicalAddress}
                                                          className={touched.physicalAddress && errors.physicalAddress ? "has-error" : null}/>
                                            {touched.physicalAddress && errors.physicalAddress ? (
                                                <div className="error-message">{errors.physicalAddress}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="formCity">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control size="lg"
                                                          disabled={disableEdit}
                                                          type="text"
                                                          name="city"
                                                          placeholder="City"
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.city}
                                                          className={touched.city && errors.city ? "has-error" : null}/>
                                            {touched.city && errors.city ? (
                                                <div className="error-message">{errors.city}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="selectId">
                                            <Form.Label>Province/territory</Form.Label>
                                            <Form.Control
                                                size="lg"
                                                as="select"
                                                name="province"
                                                disabled={disableEdit}
                                                value={values.province}
                                                className={touched.province && errors.province ? "has-error" : null}
                                                onChange={handleChange}
                                            >
                                                <option className="[ dropdown__option ]">
                                                    Province / Territory
                                                </option>
                                                {provinceList}
                                            </Form.Control>
                                            {touched.province && errors.province ? (
                                                <div className="error-message">{errors.province}</div>
                                            ) : null}
                                        </Form.Group>

                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="formPostal">
                                            <Form.Label>Postal code</Form.Label>
                                            <MaskedInput
                                                disabled={disableEdit}
                                                mask={POSTAL.CA_POSTALMASK}
                                                className={touched.zip && errors.zip ? "form-control form-control-lg has-error" : "form-control form-control-lg"}
                                                placeholder="A1A 1A1"
                                                guide={false}
                                                id="zip"
                                                name="zip"
                                                value={values.zip}
                                                onChange={handleChange}
                                            />
                                            {touched.zip && errors.zip ? (
                                                <div className="error-message">{errors.zip}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {isMobile && idType !== "Passport" && (
                                    <>
                                        <Row>
                                            <Col sm={12} className="text-center verify-title"><h2>Did the information
                                                from your ID scan correctly?</h2></Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6}><Button variant="primary" className="verify-btn" block size="lg"
                                                                type="button"
                                                                onClick={this.infoCorrect.bind(this)}>Yes</Button></Col>
                                            <Col xs={6}><Button variant="primary" className="verify-btn" block size="lg"
                                                                type="button"
                                                                onClick={this.infoNotCorrect.bind(this)}>No</Button></Col>
                                        </Row>
                                        {!disableEdit && (

                                            <span className="verify-message">Please update your information and then continue.</span>

                                        )}
                                    </>
                                )}
                                <Row className="submit-btn-container">
                                    <Col md={{span: 6, offset: 3}}>
                                        <Button type="submit" variant="primary" block size="lg"
                                                disabled={isSubmitting || !allowContinue}>
                                            Continue
                                        </Button></Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
                <br/>
                <br/>
            </Layout>
        );
    }
}