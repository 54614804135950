import Button from 'react-bootstrap/Button';
import {Col, Container, Form, Modal, Row} from 'react-bootstrap';
import React, {Component} from "react";
import {ID_LIST, IDEVERIFICATION_AUTH, IDVERIFICATION_FAILED_CODE, verifyDoc,} from "../lib/instnt";
import {INSTNT_TXN_ID, TEST_BACK, TEST_FRONT, TEST_SELFIE} from "../lib/test";
import {
    ATTEMPTS,
    ERROR_CODE,
    HTTP_STATUS,
    S_INSTNT_FINGER_PRINT,
    S_INSTNT_TXN_ID,
    URL_SUBMIT_VERIFY
} from "../lib/Constants";
import {appendScript} from "../lib/ExternalScript";
import Card from "react-bootstrap/Card";
import axios from "axios";
import axiosRetry from 'axios-retry';
import {STRINGS} from "../lib/Strings";
import Layout from "../components/Layout";
import {InstntDocumentProcessor, InstntSelfieProcessor, InstntSignupProvider} from "@instnt/instnt-react-js";

const verifyMessages = [
    "Verifying your identity... please wait.",
    "Verifying your identity... please wait.",
    "Validating ID to Facial Biometrics... please wait",
    "Validating ID to Facial Biometrics... please wait",
    "Validating Social Biometrics... please wait.",
    "Validating Social Biometrics... please wait.",
    "Validating Social Biometrics... please wait.",
    "Validating Social Biometrics... please wait.",
    "Validating Social Biometrics... please wait.",
    "Validating Social Biometrics... please wait.",
    "Validating Social Biometrics... please wait.",
    "Validating Social Biometrics... please wait.",
    "Validating Social Biometrics... please wait.",
    "Validating Social Biometrics... please wait.",
    "Validating Social Biometrics... please wait.",
];

let selectIdListElements = [];
selectIdListElements = ID_LIST.map((elem, idx) => {
    return (
        <option
            value={idx}
            className="[ dropdown__option ]"
        >
            {elem.description}
        </option>
    );
});

const SCREEN = {
    IDSELECTION: "idSelection",
    SCANIDFRONT: "scanIdFront",
    SCANIDBACK: "scanIdBack",
    SELFIE: "selfie",
    REVIEW: "review",
};

const getFailedResponse = (reasonCodes) => {
    return IDVERIFICATION_FAILED_CODE[reasonCodes[0]];
};

const getUserCheckList = (response) => {
    let authCheckList = getAuthCheckList(response);
    if (
        (!response.matchSelfie || response.matchSelfie !== "Verified") &&
        response.authentication !== IDEVERIFICATION_AUTH.VERIFIED_BUT_NOT_PASS
    ) {
        authCheckList.captureSelfie = true;
    }
    return authCheckList;
};

const getAuthCheckList = (response) => {
    switch (response.authentication) {
        case IDEVERIFICATION_AUTH.VERIFIED:
        case IDEVERIFICATION_AUTH.FRAUD_SHIELD:
            return {
                profile: true,
                selectId: false,
                captureFrontId: false,
                captureBackId: false,
                captureSelfie: false,
            };
        case IDEVERIFICATION_AUTH.RECAPTURE_SELFIE:
            return {
                profile: true,
                selectId: false,
                captureFrontId: false,
                captureBackId: false,
                captureSelfie: true,
            };
        case IDEVERIFICATION_AUTH.RECAPTURE_ID:
            return {
                profile: true,
                selectId: false,
                captureFrontId: true,
                captureBackId: true,
                // captureFrontId: false,
                // captureBackId: false,
                captureSelfie: false,
            };
        case IDEVERIFICATION_AUTH.FAILED:
            return getFailedResponse(response.reasonCodes);
        case IDEVERIFICATION_AUTH.RECAPTURE_ALL:
        case IDEVERIFICATION_AUTH.ID_EXPIRATION:
        case IDEVERIFICATION_AUTH.UNSUPPORTED_ID:
        case IDEVERIFICATION_AUTH.POTENTIAL:
        case IDEVERIFICATION_AUTH.SUBMISSION_ERROR:
        default:
            return {
                profile: true,
                selectId: true,
                captureFrontId: true,
                captureBackId: true,
                captureSelfie: true,
            };
    }
};

class Id extends Component {

    constructor(props) {
        super(props);
        this.state = {
            testOnly: process.env.REACT_APP_TEST_ONLY,
            checkInList: IDVERIFICATION_FAILED_CODE.INITIAL,
            selectedOption: "Select your ID Type",
            frontImage: null,
            backImage: null,
            selfieImage: null,
            showId: true,
            showSelfie: false,
            showBack: false,
            showFont: false,
            scanError: null,
            frontError: null,
            backError: null,
            selfieError: null,
            instnttxnid: null,
            showSpinner: false,
            idIndex: 0,
            idType: "Passport",
            idDesc: "Canadian Passport",
            showModal: false,
            modalMessage: null,
            isProcessing: false,
            spinnerMessage: "",
            processingCount: ATTEMPTS,
            retake: false,
            retakeFrontComplete: false,
            retakeBackComplete: false,
            retakeSelfieComplete: false,
            doCapture: false,
        };

    }

    componentDidMount() {
        this._ismounted = true;
        const {history} = this.props;
        const token = sessionStorage.getItem("TOKEN");
        if (!token) {
            history.push("/");
        } else {
            appendScript("/instnt.js");
            this.setState({capture: window.IDMetricsCaptureFramework});
            const idIndex = sessionStorage.getItem("ID_INDEX");
            const idType = sessionStorage.getItem("ID_TYPE");
            const idDesc = sessionStorage.getItem("ID_DESC");
            if (idIndex) {
                this.setState({
                    idIndex,
                    idType,
                    idDesc,
                })
            } else {
                //default to canada passport
                sessionStorage.setItem("ID_INDEX", "0");
                sessionStorage.setItem("ID_TYPE", "Passport");
                sessionStorage.setItem("ID_DESC", "Canadian passport");
            }
        }
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    handleSelect(event) {
        const ID = ID_LIST[event.target.value];
        console.log("handleSelect", ID);
        sessionStorage.setItem("ID_INDEX", event.target.value);
        sessionStorage.setItem("ID_TYPE", ID.type);
        sessionStorage.setItem("ID_DESC", ID.description);
        this.setState({
            idIndex: event.target.value,
            idType: ID.type,
            idDesc: ID.description,
        });
    }

    handleSelectId(event) {
        if (event) event.preventDefault();
        this.getNextActivePart(this.state.checkInList);
    }

    handleFront(event) {
        if (event) event.preventDefault();
        let frontImage = this.state.frontImage;
        if (!frontImage && this.state.testOnly) {
            frontImage = TEST_FRONT;
        }
        if (frontImage) {
            this.setState({frontImage, scanError: ""});
            this.getNextActivePart(this.state.checkInList);
        } else {
            this.setState({
                frontError: "Please capture front of your id."
            })
        }
    }

    handleBack(event) {
        if (event) event.preventDefault();
        let backImage = this.state.backImage;
        if (!backImage && this.state.testOnly) {
            backImage = TEST_BACK;
        }
        if (backImage) {
            this.setState({backImage, scanError: ""});
            this.getNextActivePart(this.state.checkInList);
        } else {
            this.setState({
                backError: "Please capture back of your id."
            })
        }
    }

    async submitVerify() {
        const {history} = this.props;
        const {instnttxnid} = this.state;
        this.setState({
            showSpinner: true,
            spinnerMessage: verifyMessages[0],
        });
        const otpId = sessionStorage.getItem("OTP_ID");
        const token = sessionStorage.getItem("TOKEN");
        axiosRetry(axios, {
            retries: verifyMessages.length, // number of retries
            retryDelay: (retryCount) => {
                console.log(`retry attempt: ${retryCount}`);
                this.setState({
                    spinnerMessage: verifyMessages[retryCount],
                });
                return retryCount * 4000; // time interval between retries
            },
            retryCondition: (error) => {
                console.log(error.response);
                // if retry condition is not specified, by default idempotent requests are retried
                return error.response?.status === 500 ||
                    error.response?.data?.code === ERROR_CODE.CANNOT_DECRYPT ||
                    error.response?.data?.code === ERROR_CODE.FAIL_ASSERTION ||
                    error.response?.data?.code === ERROR_CODE.DATA_NOT_AVAILABLE;

            },
        });

        await axios.post(URL_SUBMIT_VERIFY + "/" + otpId, {instnttxnid}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            this.setState({
                showSpinner: false,
                spinnerMessage: "",
            });
            this.handleSubmitIDVerify(response);
        }).catch((err) => {
            console.log(err.status, err.response);
            console.log(`API call failed with status code: ${err.response.status}`);
            sessionStorage.setItem("END_FLAG", "ERROR");
            history.push("/end?code=" + err.response.status);
        });
    }

    handleSelfie(event) {
        console.log("handleSelfie");
        const {instnttxnid, idType} = this.state;
        if (event) event.preventDefault();
        let selfieImage = this.state.selfieImage;
        if (!selfieImage && this.state.testOnly) {
            selfieImage = TEST_SELFIE;
        }
        ;
        if (selfieImage) {
            sessionStorage.setItem("SELFIE_IMAGE", selfieImage);
            const instnt_token = window.instnt.getToken();
            let request = {instnttxnid, formKey: process.env.REACT_APP_INSTNT_KEY, documentType: idType};
            this.setState({
                isProcessing: true,
                showSpinner: true,
                spinnerMessage: "Not that we don't trust you, but we need about 10 seconds or so to verify your ID... Please Hold!",
            });
            verifyDoc(request).then((result) => {
                console.log(result);
                setTimeout(function () {
                    this.submitVerify(result);
                }.bind(this), 3000);
            });

            // router.push("/review");
        } else {
            this.setState({
                selfieError: "Please capture a selfie."
            })
        }

    }

    onEventHandler = (event) => {
        console.log("Instnt event: ", event);
        const {checkInList, testOnly} = this.state;
        switch (event.type) {
            case "transaction.initiated":
                const fingerprint = document.getElementById("fingerprint_txt");
                console.log("testOnly", testOnly);
                if (testOnly) {
                    this.setState({
                        insnt: event.data.instnt,
                        instnttxnid: INSTNT_TXN_ID,
                        fingerprint: fingerprint.value,
                    });
                    sessionStorage.setItem(S_INSTNT_TXN_ID, INSTNT_TXN_ID);
                    console.log("new instnttxnid", INSTNT_TXN_ID);
                } else {
                    this.setState({
                        insnt: event.data.instnt,
                        instnttxnid: event.data.instnt.instnttxnid,
                        fingerprint: fingerprint.value,
                    });
                    sessionStorage.setItem(S_INSTNT_TXN_ID, event.data.instnt.instnttxnid);
                    console.log("new instnttxnid", event.data.instnt.instnttxnid);
                }
                sessionStorage.setItem(S_INSTNT_FINGER_PRINT, fingerprint.value);
                break;
            case "document.captured":
                this.setState({
                    documentSettings: event.data.documentSettings,
                    selfieSettings: event.data.selfieSettings,
                    captureResult: event.data.captureResult,
                    doCapture: false,
                });
                if (checkInList.captureFrontId && !checkInList.selectId) {
                    this.setState({frontImage: event.data.captureResult.result, scanError: ""})
                } else if (checkInList.captureBackId && !checkInList.captureFrontId && !checkInList.selectId) {
                    this.setState({backImage: event.data.captureResult.result, scanError: ""})
                } else if (checkInList.captureSelfie && !checkInList.captureBackId && !checkInList.captureFrontId && !checkInList.selectId) {
                    this.setState({selfieImage: event.data.captureResult.result, scanError: ""})
                }
                break;
            case "document.capture-cancelled":
                this.setState({doCapture: false});
                break;
            default:
                console.log("unhandled instnt event ", event);
        }
    };

    scanFrontID = () => {
        this.setState({doCapture: true});
    };


    scanBackID = () => {
        this.setState({doCapture: true});
    };

    scanSelfie = () => {
        this.setState({doCapture: true});
    };

    convertBase64ImageToString = (dataUri) => {
        let byteString;
        if (dataUri.split(",")[0].indexOf("base64") <= 0)
            byteString = atob(dataUri.split(",")[1]);
        else byteString = unescape(dataUri.split(",")[1]);

        return byteString;
    }

    setFormData = (formData) => {
        console.log(formData);
        // const { postalCode } = formData;
        // const postalCodeFormatted = postalCode
        //     ? conformToMask(postalCode, AppConstants.ABOUT_YOU.CA_POSTALMASK, {
        //         guide: false,
        //     }).conformedValue
        //     : postalCode;
        this.setState({
            formData: {...formData},
        });
    };

    getNextActivePart = (checkList) => {
        console.log("getNextActivePart", checkList);
        const {idType} = this.state;
        const {
            selectId,
            captureFrontId,
            captureBackId,
            captureSelfie,
            profile,
        } = checkList;
        let checkInList = null;
        if (selectId) {
            checkInList = {
                selectId: false,
                captureFrontId,
                captureBackId,
                captureSelfie,
                profile,
            };
        } else if (captureFrontId) {
            checkInList = {
                selectId,
                captureFrontId: false,
                captureBackId,
                captureSelfie,
                profile,
            };
        } else if (captureBackId) {
            checkInList = {
                selectId,
                captureFrontId,
                captureBackId: false,
                captureSelfie,
                profile,
            };
        } else if (captureSelfie) {
            checkInList = {
                selectId,
                captureFrontId,
                captureBackId,
                captureSelfie: false,
                profile,
            };
        }

        if (idType === "Passport") {
            checkInList.captureBackId = false;
        }

        if (selectId === false && captureFrontId === false && captureBackId === false && captureSelfie === false) {
            this.handleSelfie();
        } else if (checkInList.selectId === false && checkInList.captureFrontId === false && checkInList.captureBackId === false && checkInList.captureSelfie === false) {
            this.handleSelfie();
        } else {
            if (!checkInList) {
                checkInList = {
                    selectId,
                    captureFrontId,
                    captureBackId,
                    captureSelfie,
                    profile,
                }
            }

            console.log("updating checkInList state", checkInList);
            this.setState({checkInList});
        }
    };

    handleSubmitIDVerify = (response) => {
        console.log("handleSubmitIDVerify", response);
        const {history} = this.props;
        const {processingCount} = this.state;
        if (!response) {
            console.log("undefined submit verify response");
            sessionStorage.setItem("END_FLAG", "ERROR");
            history.push("/end?code=500");
        } else if (response.status === HTTP_STATUS.OK) {
            sessionStorage.setItem("INSTNT_DATA", JSON.stringify(response.data));
            if (processingCount - 1 > 0) {
                const checkInList = getUserCheckList(response.data);
                if (!checkInList.captureFrontId && !checkInList.captureBackId && !checkInList.captureSelfie) {
                    history.push("/review");
                } else {
                    const count = processingCount - 1;
                    if (checkInList.captureFrontId) {
                        this.setState({
                            frontImage: null
                        });
                    }
                    ;
                    if (checkInList.captureBackId) {
                        this.setState({
                            backImage: null
                        });
                    }
                    ;
                    if (checkInList.captureSelfie) {
                        this.setState({
                            selfieImage: null
                        });
                    }
                    ;
                    this.setState({
                        checkInList,
                        processingCount: count,
                        retake: true,
                    });
                    let errorMessage = response.data?.authentication;
                    if (errorMessage.startsWith(IDEVERIFICATION_AUTH.DOCUMENT_NOT_PASS)) {
                        this.setState({
                            showModal: true,
                            modalMessage: STRINGS.GENERIC_ERROR_MESSAGE,
                        });
                    } else if (
                        response.data?.authentication === IDEVERIFICATION_AUTH.VERIFIED &&
                        response.data?.matchSelfie
                    ) {
                        this.setState({
                            showModal: true,
                            modalMessage: response.data?.matchSelfie,
                        });
                    }
                }
            } else {
                history.push("/review");
            }
        } else {
            history.push("/");
        }
    };

    handleClose() {
        this.setState({
            showModal: false,
            modalMessage: null,
        });
    }

    render() {
        const {
            showSpinner,
            frontImage,
            backImage,
            selfieImage,
            showModal,
            modalMessage,
            isProcessing,
            checkInList,
            spinnerMessage,
            idType,
            idDesc,
            retake,
            retakeFrontComplete,
            retakeBackComplete,
            retakeSelfieComplete,
            doCapture,
            testOnly,
        } = this.state;
        const {history} = this.props;

        const frontLicenseSettings = {
            documentType: idType,
            documentSide: "Front",
            captureMode: "Auto",
            licenseFaceDetectionProportionMin: 1.4,
            passportFaceDetectionProportionMin: 1.4,
        };
        const backLicenseSettings = {
            documentType: idType,
            documentSide: "Back",
            captureMode: "Auto",
        };
        const selfieSettings = {
            captureMode: "Auto",
        };
        return (
            <Layout showSpinner={showSpinner} history={history} spinnerMessage={spinnerMessage}>

                <Modal
                    show={showModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={this.handleClose.bind(this)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Error!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{modalMessage ? modalMessage : "Error encountered processing your info..."}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose.bind(this)}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <InstntSignupProvider
                    formKey={process.env.REACT_APP_INSTNT_KEY}
                    onEvent={this.onEventHandler}
                    serviceURL={process.env.REACT_APP_INSTNT_URL}
                    idmetrics_version={process.env.REACT_APP_METRICS_VERSION}
                >
                    {checkInList.selectId && (
                        <Container className="form-fields">
                            <Row>
                                <Col sm={12}><h1>Select your ID type</h1></Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId="selectId">
                                        <Form.Label>Select your ID</Form.Label>
                                        <Form.Control size="lg" as="select" onChange={this.handleSelect.bind(this)}>
                                            {selectIdListElements}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="submit-btn-container">
                                <Col sm={12}>
                                    <Button type="submit" variant="primary" block size="lg"
                                            onClick={this.handleSelectId.bind(this)}>
                                        Continue
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {checkInList.captureFrontId && !checkInList.selectId && (
                        <Container className="form-fields">
                            {doCapture && (
                                <InstntDocumentProcessor
                                    documentSettings={frontLicenseSettings}
                                    documentType={idType}
                                    documentSide="Front"
                                    captureMode="Auto"
                                />
                            )}
                            <Row>
                                <Col sm={12}>
                                    {!retakeFrontComplete && idType !== "Passport" && (
                                        <h1 className={retake ? "error-message" : ""}>{retake ? STRINGS.FRONT.RETAKE : STRINGS.FRONT.TAKE} {idDesc}.</h1>
                                    )}
                                    {!retakeFrontComplete && idType === "Passport" && (
                                        <h1 className={retake ? "error-message" : ""}>{retake ? STRINGS.FRONT.RETAKE_PASSPORT : STRINGS.FRONT.TAKE_PASSPORT} {idDesc}.</h1>
                                    )}
                                </Col>
                            </Row>
                            <Row className="justify-content-center flex-grow-1">
                                <Col sm={12} className="upload-buttons-container">
                                    <Card className="text-center upload-buttons" onClick={this.scanFrontID.bind(this)}>
                                        <Card.Body>
                                            <Card.Img variant="top" src={frontImage ? frontImage : '/frontID.svg'}
                                                      className="icon icon-frontID"/>
                                            <Card.Text>
                                                {frontImage ? STRINGS.FRONT.TAP_RETAKE : STRINGS.FRONT.TAP_BEGIN}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    {this.state.frontError ? (
                                        <div className="error-message">{this.state.frontError}</div>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className="submit-btn-container">
                                <Col sm={12}>
                                    <Button type="submit" variant="primary" block size="lg"
                                            disabled={!(frontImage || testOnly)}
                                            onClick={this.handleFront.bind(this)}>
                                        Next
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {checkInList.captureBackId && !checkInList.captureFrontId && !checkInList.selectId && (
                        <Container className="form-fields">
                            {doCapture && (
                                <InstntDocumentProcessor
                                    documentSettings={backLicenseSettings}
                                    documentType={idType}
                                    documentSide="Back"
                                    captureMode="Auto"
                                />
                            )}
                            <Row>
                                <Col sm={12}>
                                    {!retakeBackComplete && (
                                        <h1 className={retake ? "error-message" : ""}>{retake ? STRINGS.BACK.RETAKE : STRINGS.BACK.TAKE} {idDesc}.</h1>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Card className="text-center upload-buttons" onClick={this.scanBackID.bind(this)}>
                                        <Card.Body>
                                            <Card.Img variant="top" src={backImage ? backImage : '/backID.svg'}
                                                      className="icon icon-backID"/>
                                            <Card.Text>
                                                {backImage ? STRINGS.BACK.TAP_RETAKE : STRINGS.BACK.TAP_BEGIN}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    {this.state.backError ? (
                                        <div className="error-message">{this.state.backError}</div>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className="submit-btn-container">
                                <Col sm={12}>
                                    <Button type="submit" variant="primary" block size="lg"
                                            disabled={!(backImage || testOnly)}
                                            onClick={this.handleBack.bind(this)}>
                                        Next
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {checkInList.captureSelfie && !checkInList.captureBackId && !checkInList.captureFrontId && !checkInList.selectId && (
                        <Container className="form-fields">
                            {doCapture && (
                                <InstntSelfieProcessor
                                    documentType={idType}
                                    selfieSettings={selfieSettings}
                                />
                            )}
                            <Row>
                                <Col sm={12}>
                                    {!retakeSelfieComplete && (
                                        <h1 className={retake ? "error-message" : ""}>{retake ? STRINGS.SELFIE.RETAKE : STRINGS.SELFIE.TAKE}</h1>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Card className="text-center upload-buttons" onClick={this.scanSelfie.bind(this)}>
                                        <Card.Body>
                                            <Card.Img variant="top" src={selfieImage ? selfieImage : '/selfie.svg'}
                                                      className="icon icon-selfie"/>
                                            <Card.Text>
                                                {selfieImage ? STRINGS.SELFIE.TAP_RETAKE : STRINGS.SELFIE.TAP_BEGIN}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    {this.state.selfieError ? (
                                        <div className="error-message">{this.state.selfieError}</div>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className="submit-btn-container">
                                <Col md={{span: 6, offset: 3}}>
                                    <Button type="submit" variant="primary" block size="lg"
                                            disabled={!(selfieImage || testOnly)}
                                            onClick={this.handleSelfie.bind(this)}>
                                        Next
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    )}
                </InstntSignupProvider>

            </Layout>
        );
    }
}

export default Id;