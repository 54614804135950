import React, {Component} from "react";
import Button from 'react-bootstrap/Button';
import {Container, Modal} from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showEscapeModal: false,
        }
    }

    handleCloseEscape = () => {
        this.setState({
            showEscapeModal: false
        });
    }

    handleShowEscape = () => {
        this.setState({
            showEscapeModal: true
        });
    }

    endProcess = () => {
        const {history} = this.props;
        sessionStorage.setItem("END_FLAG", "LEAVING");
        history.push("/end");
    }

    render() {
        const {showEscapeModal} = this.state;
        const {atEnd} = this.props;
        return (
            <>
                <Modal
                    show={showEscapeModal}
                    onHide={this.handleCloseEscape}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Leaving so soon?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Leaving now means we will not be able to automatically verify you.</p>
                        <p>Are you sure you want to leave now?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseEscape}>
                            Cancel
                        </Button>

                        <Button variant="primary" onClick={() => this.endProcess()}>
                            Leave now
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Navbar bg="light" expand="lg">
                    <Container fluid="sm" className="justify-content-center">
                        {!atEnd && (
                            <button
                                type="button"
                                className="close"
                                onClick={this.handleShowEscape}
                            >
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                        )}
                        <a className="navbar-brand" href="#">
                            <img src="/community.svg" width="250" height="40" alt="Community Savings Credit Union"/>
                        </a>
                    </Container>
                </Navbar>
            </>
        );
    }
}

export default Header;
