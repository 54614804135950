import Card from 'react-bootstrap/Card';
import Layout from "../components/Layout";
import {Col, Container, Row} from 'react-bootstrap';
import {Component} from "react";

class End extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLeaving: false,
            success: false,
            error: false
        }
    }

    componentDidMount() {
        const {history} = this.props;
        const endFlag = sessionStorage.getItem("END_FLAG");
        if (endFlag) {
            if (endFlag === "LEAVING") {
                this.setState({
                    isLeaving: true
                })
            } else if (endFlag === "ERROR") {
                this.setState({
                    error: true
                })
            } else {
                this.setState({
                    success: true
                })
            }
            sessionStorage.clear();
        } else {
            history.push("/");
        }
    }

    render() {
        const {
            isLeaving,
            success,
            error,
        } = this.state;
        return (
            <Layout atEnd={true}>
                <Container className="form-fields">
                    <Row className="end-cards">
                        {success && (
                            <Col sm={12} className="end-card-container verified">
                                <Card>
                                    <Card.Body>
                                        <Card.Img variant="top" src="/check.svg" className="icon icon-check"/>
                                        <h1>Success!</h1>
                                        <Card.Text>
                                            <p>We have received your identification information and a member of the
                                                Community Savings team will review the details and be in touch with
                                                you.</p>
                                            <p>If you have any questions or concerns, please contact us at <a
                                                href="tel:+16046542000">604-654-2000</a> or <a
                                                href="mailto:reception@comsavings.com">reception@comsavings.com</a>.</p>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <h4>You can now close this browser window.</h4>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        )}
                        {error && (
                            <Col sm={12} className="end-card-container not-verified">
                                <Card>
                                    <Card.Body>
                                        <Card.Img variant="top" src="/alert.svg" className="icon icon-alert"/>
                                        <h1>Hmm...</h1>
                                        <Card.Text>
                                            <p>Unfortunately we were unable to automatically verify you at this
                                                time.</p>
                                            <p>A confirmation email has been sent to your email address. A
                                                representative from CSCU will reach out to you soon.</p>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <h4>You can now close this browser window.</h4>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        )}
                        {isLeaving && (
                            <Col sm={12} className="end-card-container escape">
                                <Card>
                                    <Card.Body>
                                        <Card.Img variant="top" src="/grin.svg" className="icon icon-grin"/>
                                        <h1>Leaving us?</h1>
                                        <Card.Text>
                                            <p>You are now finished with automatic verification. Any information you may
                                                have entered has been removed from our systems.</p>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <h4>You can now close this browser window.</h4>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Container>
            </Layout>
        );
    }
}

export default End