import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Layout from "../components/Layout";
import {Col, Container, Row} from 'react-bootstrap';
import {Component} from "react";
import {isMobile} from "react-device-detect";

class Main extends Component {

    componentDidMount() {
        sessionStorage.removeItem("EMAIL");
        sessionStorage.removeItem("PHONE");
        sessionStorage.removeItem("OTP_ID");
        sessionStorage.removeItem("TOKEN");
        sessionStorage.removeItem("SELFIE_IMAGE");
        sessionStorage.removeItem("INSTNT_DATA");
    }

    render() {
        const {history} = this.props;
        return (
            <Layout history={history}>
                <Container className="form-fields">
                    <Row>
                        <Col md={{span: 10, offset: 1}}><h1>Welcome to CSCU's online identity verification</h1></Col>
                    </Row>
                    <Row className="pb-3">
                        <Col md={{span: 10, offset: 1}}><h3>In order to verify your identity you will need:</h3></Col>
                    </Row>
                    <Row className="info-cards">
                        {isMobile && (
                            <>
                                <Col md={4} className="info-card-container">
                                    <Card className="text-center">
                                        <Card.Body>
                                            <Card.Img variant="top" src="/license.svg" className="icon icon-license"/>
                                            <Card.Text>
                                                a valid government-issued identity document
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className="info-card-container">
                                    <Card className="text-center">
                                        <Card.Body>
                                            <Card.Img variant="top" src="/camera.svg" className="icon icon-camera"/>
                                            <Card.Text>
                                                be prepared to take a selfie and photos of your ID
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className="info-card-container">
                                    <Card className="text-center">
                                        <Card.Body>
                                            <Card.Img variant="top" src="/bulb.svg" className="icon icon-bulb"/>
                                            <Card.Text>
                                                to be sure you are in a well-lit area
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </>
                        )}
                        <Col md={{span: 6, offset: 3}} className="info-card-container">
                            <Card className="text-center">
                                <Card.Body>
                                    <Card.Img variant="top" src="/mobile.svg" className="icon icon-mobile"/>
                                    <Card.Text>
                                        your mobile device
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="submit-btn-container">
                        <Col md={{span: 6, offset: 3}}>
                            <Button variant="primary" block size="lg" onClick={() => this.props.history.push("/info")}>
                                Let's begin
                            </Button>
                        </Col>
                    </Row>
                </Container>

            </Layout>
        );
    }
}

export default Main
