import React from "react";

export const STRINGS = {
    FRONT: {
        TAKE: "Take a photo of the front of your ",
        RETAKE: "Please take another photo of the front of your ",
        TAKE_PASSPORT: "Take a photo of the main photo page inside of your ",
        RETAKE_PASSPORT: "Please re-take the photo of the main photo page inside your ",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake? Tap to retake",
    },
    BACK: {
        TAKE: "Now take a photo of the back of your ",
        RETAKE: "Please take another photo of the back of your ",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake? Tap to retake",
    },
    SELFIE: {
        TAKE: "Lastly, take a selfie.",
        RETAKE: "Please take another selfie.",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake selfie? Tap to retake",
    },
    GENERIC_ERROR_MESSAGE:
        "We were unable to process your ID scan as it was submitted. You will need to retry scanning your document again.",
}